import React, { useState } from 'react';

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // Hard-code the password here (change "YourSecretPassword" to your desired password)
  const correctPassword = "KateLovesNav1806";

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      onLogin();  // Notify parent that login was successful.
    } else {
      setError("Incorrect password. If you're not Kate, go away.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-emerald-50">
      <h2 className="text-2xl font-bold mb-4">Please confirm that you are Kate</h2>
      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
          className="p-2 border border-gray-300 rounded mb-2"
        />
        <button
          type="submit"
          className="px-4 py-2 bg-emerald-600 text-white rounded hover:bg-emerald-700"
        >
          Login
        </button>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </form>
    </div>
  );
};

export default Login;
