import React, { useState, useRef, useEffect } from 'react';
import { Send, Loader2, ChevronDown } from 'lucide-react';

const TypingIndicator = () => (
  <div className="flex space-x-2 p-3 bg-gray-50 rounded-2xl w-16">
    <div className="w-2 h-2 bg-emerald-400 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
    <div className="w-2 h-2 bg-emerald-400 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
    <div className="w-2 h-2 bg-emerald-400 rounded-full animate-bounce"></div>
  </div>
);

const ModernChatInterface = () => {
  // Initialize conversation with a system message and the default assistant greeting.
  const [conversation, setConversation] = useState([
    {
      role: "system",
      content:
        "You are Navjeev. Respond in a warm, loving, and authentic style. Kate is your girlfriend, and you care deeply about her."
    },
    {
      role: "assistant",
      content: "Hey Kate! I'm here if you want to chat 💚"
    }
  ]);

  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const typingTimeoutRef = useRef(null);

  // Scroll to bottom helper
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Handle scrolling, show a "scroll to bottom" button if near the top
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 100;
    setShowScrollButton(!bottom);
  };

  useEffect(scrollToBottom, [conversation, isTyping]);

  // Detect typing
  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    setIsTyping(true);
    typingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false);
    }, 1000);
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    // Create new user message
    const newUserMessage = {
      role: "user",
      content: inputMessage.trim()
    };

    // Append new user message to conversation history
    const updatedConversation = [...conversation, newUserMessage];
    setConversation(updatedConversation);
    setInputMessage('');
    setIsLoading(true);
    setIsTyping(false);

    try {
      // Call the backend API with the full conversation history.
      const response = await fetch(
        'https://ainavjeev-ac634abdc790.herokuapp.com/chat',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ conversation: updatedConversation })
        }
      );

      const data = await response.json();

      // Safely handle the assistant's response; fallback if it's missing
      const newAssistantMessage = {
        role: "assistant",
        content: data?.response
          ? data.response
          : "Sorry, something went wrong (no response from server)."
      };

      // Append the assistant's response to the conversation
      setConversation((prev) => [...prev, newAssistantMessage]);
    } catch (error) {
      console.error('Error:', error);
      // If an error occurs, ensure we still provide content
      setConversation((prev) => [
        ...prev,
        {
          role: "assistant",
          content: "Sorry, something went wrong."
        }
      ]);
    } finally {
      setIsLoading(false);
      setIsTyping(false);
    }
  };

  // For display, filter out system messages
  const displayMessages = conversation.filter(
    (msg) => msg.role === "user" || msg.role === "assistant"
  );

  return (
    <div className="flex flex-col h-screen max-w-2xl mx-auto bg-white shadow-xl relative">
      {/* Header */}
      <div className="bg-emerald-600 p-4 text-white">
        <h1 className="text-xl font-semibold">Chat with AI Navjeev</h1>
        <p className="text-sm text-emerald-100">
          Always here for you, especially when the real one is busy 💚
        </p>
      </div>

      {/* Chat window */}
      <div
        className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50"
        onScroll={handleScroll}
      >
        {displayMessages.map((msg, index) => (
          <div
            key={index}
            className={`flex ${
              msg.role === 'user' ? 'justify-end' : 'justify-start'
            }`}
          >
            <div
              className={`max-w-[80%] rounded-2xl px-4 py-2 ${
                msg.role === 'user'
                  ? 'bg-emerald-600 text-white'
                  : 'bg-white border border-emerald-100 text-gray-800'
              }`}
            >
              <p className="break-words">{msg.content}</p>
            </div>
          </div>
        ))}

        {/* Typing indicator */}
        {isTyping && (
          <div className="flex justify-start">
            <TypingIndicator />
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      {/* Scroll to bottom button */}
      {showScrollButton && (
        <button
          onClick={scrollToBottom}
          className="absolute bottom-20 right-4 p-2 bg-emerald-600 text-white rounded-full shadow-lg hover:bg-emerald-700 transition-colors"
        >
          <ChevronDown className="h-5 w-5" />
        </button>
      )}

      {/* Input area */}
      <form onSubmit={handleSubmit} className="p-4 bg-white border-t border-gray-200">
        <div className="flex items-center space-x-2">
          <input
            type="text"
            value={inputMessage}
            onChange={handleInputChange}
            placeholder="Type your message..."
            className="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
          />
          <button
            type="submit"
            disabled={isLoading || !inputMessage.trim()}
            className="p-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoading ? (
              <Loader2 className="h-5 w-5 animate-spin" />
            ) : (
              <Send className="h-5 w-5" />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ModernChatInterface;
