import React, { useState } from 'react';
import ModernChatInterface from './ModernChatInterface';
import Login from './Login';

function App() {
  // State to track if Kate has successfully logged in.
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <div>
      {isLoggedIn ? (
        <ModernChatInterface />
      ) : (
        <Login onLogin={() => setIsLoggedIn(true)} />
      )}
    </div>
  );
}

export default App;
